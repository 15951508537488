@import "global";

.container {
    position: relative;

    input {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 0.5em;
        border: $border solid $blueWhite;
        font-size: inherit;
        color: $black;

        &:focus {
            border-color: $primary;
            outline: none;
        }
    }

    .suggestionsList {
        list-style-type: none;
        margin: 0;
        padding: 0;
        
        .suggestion {
            width: 100%;
            padding: .5em;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            color: white;
            background-color: $primary;
            border: $border solid $primary;
            cursor: pointer;
        }

        .suggestionHighlighted {
            background-color: $darkPrimary;
            border-color: $darkPrimary;
        }
    }
}