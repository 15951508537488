@import "global";

.glossary {
    > .sortAndFilter {
        display: flex;
        align-items: center;

        @include phone {
            flex-direction: column;
        }

        h2 {
            font-weight: normal;
            margin: 0;
            margin-right: $spacing/2;
        }

        > div {
            display: inline-block;
            width: 180px;
            flex-shrink: 1;
            margin: $spacing/2;

            > * {
                width: 100%;
                // 1em text + .5em*2 padding + $border*2 borders + 4px manual adjustment
                height: calc(2em + #{$border}*2 + 4px);
            }
        }
    }
}