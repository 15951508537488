@import "global";

.sortSelect {
    padding: .5em;
    box-sizing: border-box;
    font-size: inherit;
    color: $dark;
    border: $border solid $blueWhite;
    outline: none;

    &:focus {
        border-color: $primary;
    }

    // Fix for Firefox dotted outline
    &:-moz-focusring {
        color: transparent;
        text-shadow: 0 0 0 #000;
    }
}