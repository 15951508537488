@charset "UTF-8";
.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column; }
  .App > .top {
    display: flex;
    flex-grow: 1;
    box-sizing: border-box;
    font-size: 16px;
    background-color: white; }
    @media screen and (max-width: 639px) {
      .App > .top {
        flex-direction: column; } }
    .App > .top > .column {
      display: flex;
      flex-direction: column;
      flex-grow: 1; }
      .App > .top > .column > .columnHeader {
        flex-shrink: 0;
        margin: 1em; }
    .App > .top > nav.column {
      text-align: right; }
      .App > .top > nav.column > .homeLink {
        padding: 24px 0;
        background-color: #282532;
        color: white;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        font-size: 2.5em;
        font-family: "Big Shoulders Text";
        font-weight: 700;
        margin: 48px 0; }
        @media screen and (max-width: 639px) {
          .App > .top > nav.column > .homeLink {
            margin: 0; } }
        .App > .top > nav.column > .homeLink > img {
          height: 1em;
          margin-right: .25em; }
        @media screen and (max-width: 1079px) and (min-width: 640px) {
          .App > .top > nav.column > .homeLink > img {
            height: 1.5em; }
          .App > .top > nav.column > .homeLink > span {
            display: none; } }
      @media screen and (min-width: 640px) {
        .App > .top > nav.column > ul > li {
          border-right: 4px solid #00a8e0; } }
      @media screen and (max-width: 639px) {
        .App > .top > nav.column > ul > li {
          height: 100%;
          box-sizing: border-box;
          vertical-align: bottom;
          border-bottom: 4px solid #00a8e0; } }
    .App > .top > aside.column {
      padding-top: 48px; }
      @media screen and (max-width: 1079px) {
        .App > .top > aside.column {
          display: none; } }
      .App > .top > aside.column > h4 {
        margin-left: 28px;
        color: #75737d;
        font-weight: normal;
        text-transform: uppercase;
        letter-spacing: 2px; }
      .App > .top > aside.column > ul > li {
        border-left: 4px solid #00a8e0; }
        .App > .top > aside.column > ul > li > a:focus {
          background-color: #bfe9f7; }
    .App > .top > nav.column, .App > .top > aside.column {
      flex-grow: 0;
      color: #282532; }
      @media screen and (min-width: 1080px) {
        .App > .top > nav.column, .App > .top > aside.column {
          min-width: 240px; } }
      @media screen and (min-width: 640px) {
        .App > .top > nav.column, .App > .top > aside.column {
          position: sticky;
          top: 0;
          align-self: flex-start; } }
      .App > .top > nav.column > ul, .App > .top > aside.column > ul {
        display: flex;
        flex-direction: column;
        padding: 0;
        margin: 0;
        height: 100%;
        list-style-type: none; }
        @media screen and (max-width: 639px) {
          .App > .top > nav.column > ul, .App > .top > aside.column > ul {
            flex-direction: row;
            justify-content: center;
            box-shadow: 0 -4px 0 rgba(0, 0, 0, 0.1) inset; } }
        .App > .top > nav.column > ul > li, .App > .top > aside.column > ul > li {
          box-sizing: border-box; }
          .App > .top > nav.column > ul > li a, .App > .top > aside.column > ul > li a {
            display: block;
            padding: 12px 24px; }
            .App > .top > nav.column > ul > li a:focus, .App > .top > aside.column > ul > li a:focus {
              outline: none; }
            .App > .top > nav.column > ul > li a:focus-within, .App > .top > aside.column > ul > li a:focus-within {
              font-weight: bold; }
          .App > .top > nav.column > ul > li .current, .App > .top > aside.column > ul > li .current {
            background-color: #bfe9f7;
            font-weight: bold; }
      .App > .top > nav.column a, .App > .top > aside.column a {
        color: inherit;
        text-decoration: none; }
    .App > .top > nav + main {
      padding: 0 48px;
      box-sizing: border-box; }
      @media screen and (max-width: 1079px) {
        .App > .top > nav + main {
          padding: 0 24px; } }
    .App > .top > main {
      min-width: 40%;
      color: #090a0b;
      overflow-wrap: break-word;
      margin-bottom: 96px; }
      .App > .top > main .contentHeader {
        text-align: center;
        padding: 24px;
        margin-bottom: 24px; }
        @media screen and (max-width: 639px) {
          .App > .top > main .contentHeader {
            margin-left: -24px;
            margin-right: -24px; } }
        .App > .top > main .contentHeader h1 {
          margin: 24px;
          font-size: 4em;
          font-weight: normal;
          color: #090a0b; }
          @media screen and (max-width: 639px) {
            .App > .top > main .contentHeader h1 {
              font-size: 3em; } }
        .App > .top > main .contentHeader p {
          font-size: 1.25em;
          color: #282532; }
  .App > footer {
    background-color: #090a0b;
    color: #75737d;
    font-size: .75em; }
    .App > footer > .footerSections {
      display: flex;
      flex-wrap: wrap;
      list-style-type: none;
      margin: 0;
      padding: 0;
      line-height: 2; }
      @media screen and (min-width: 640px) {
        .App > footer > .footerSections {
          justify-content: center; } }
      .App > footer > .footerSections > li {
        min-width: 180px;
        flex-shrink: 1;
        padding: 2em;
        box-sizing: border-box; }
        @media screen and (min-width: 640px) {
          .App > footer > .footerSections > li {
            max-width: 25%; } }
        .App > footer > .footerSections > li > h3 {
          text-transform: uppercase;
          margin-top: 0;
          color: #e4e7e5; }
        .App > footer > .footerSections > li > p {
          display: inline-block;
          margin-top: 0; }
        .App > footer > .footerSections > li > .footerLinks {
          margin: 0;
          padding-left: 1em; }
          .App > footer > .footerSections > li > .footerLinks a {
            color: inherit; }

.contentSummaryList {
  list-style-type: none;
  margin: 0;
  padding: 0; }
  .contentSummaryList > .contentSummary:not(:last-child) {
    margin-bottom: 48px; }
  .contentSummaryList > .contentSummary > a {
    display: flex;
    position: relative;
    flex-direction: row;
    color: inherit; }
    .contentSummaryList > .contentSummary > a .contentSummaryThumbnail {
      flex: 0 0;
      height: 10em;
      margin-right: 24px; }
      @media screen and (max-width: 639px) {
        .contentSummaryList > .contentSummary > a .contentSummaryThumbnail {
          display: none; } }
    .contentSummaryList > .contentSummary > a .contentSummaryText {
      border-left: 4px solid #00a8e0;
      padding-left: 24px; }
      .contentSummaryList > .contentSummary > a .contentSummaryText > h3 {
        font-size: 2em; }
      .contentSummaryList > .contentSummary > a .contentSummaryText > *:first-child {
        margin-top: 0; }
      .contentSummaryList > .contentSummary > a .contentSummaryText > *:last-child {
        margin-bottom: 0; }

.coming-soon {
  color: #75737d !important; }
  .coming-soon::after {
    content: " 🔜︎"; }
