@import "global";

article {
    > .contentHeader {
        background-color: $offWhite;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        text-align: left !important;
        padding-top: $spacing*2 !important;
        padding-bottom: $spacing*4 !important;
        margin-bottom: $spacing*2 !important;

        @include desktop {
            padding-left: $spacing*2 !important;
            padding-right: $spacing*2 !important;
        }

        @include phone {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }

        h1, p {
            display: table;
            margin: 0 !important;
            padding: $spacing/2;
            background-color: rgba($dark, .9);
            color: $offWhite !important;
        }
    }

    > .contents {
        color: $dark;

        .articleMetadata {
            margin-top: -$spacing/2;
            color: $neutral;
            font-size: .875em;
            font-weight: normal;
            text-transform: uppercase;
            letter-spacing: 2px;
        }

        h2 {
            font-size: 3em;
            font-weight: normal;
        }
        
        h3 {
            font-size: 2em;
            font-weight: normal;
        }

        ol, ul {
            padding-left: 1em;
        }
        
        li:not(:last-child) {
            margin-bottom: $spacing/2;
        }

        img {
            text-align: center;
            max-width: 100%;
        }
    }
}