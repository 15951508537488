@import "global";

.home {
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: $white;

    > .homeHeader {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: $spacing*4 $spacing*2;
        box-sizing: border-box;
        background-color: $black;
        background-image: url("/images/Home - Header.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        color: $white;

        @include tablet-or-smaller {
            flex-direction: column;
        }

        @supports (pointer-events: none) and (mix-blend-mode: difference) {
            &::before {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                background-image: url("/images/Home - Header (grain).png");
                content: " ";
                pointer-events: none;
                mix-blend-mode: difference;
            }
        }

        > * {
            padding: $spacing;
        }

        > p {
            margin: 0;
            max-width: 640px;
            line-height: 2;

            @include tablet-or-larger {
                font-size: 1.25em;
            }
        }

        > .homeLogo {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            > img {
                height: 8em;
                margin-right: 2em;
            }

            > h1 {
                font-size: 8em;
                font-family: "Big Shoulders Text";
                font-weight: 700;
                margin: 0;
            }

            @include tablet-or-smaller {
                > img {
                    height: 15vw;
                    margin-right: 3vw;
                }

                > h1 {
                    font-size: 15vw;
                }
            }
        }
    }

    > .homeSections {
        display: flex;
        margin: 0;
        padding: 0;
        list-style-type: none;
        background-color: $white;

        @include tablet-or-smaller {
            flex-direction: column;
        }

        > li {
            padding: $spacing*2;
            flex: 1;

            @include tablet-or-smaller {
                padding: $spacing;
            }

            > h2 {
                font-size: 3em;
                color: $neutral;
                margin-top: 0;
            }
            
            > a {
                color: $white;

                > h3 {
                    margin-top: 0;
                    font-size: 2em;
                    font-weight: normal;
                }

                > p {
                    margin-bottom: 0;
                }

            }

        }

    }

}