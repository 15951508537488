@import "global";

@font-face {
    font-family: 'Big Shoulders Text';
    font-style: normal;
    font-weight: 700;
    src: local('Big Shoulders Text Bold'), local('BigShouldersText-Bold'),
         url('/fonts/big-shoulders-text-v1-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('/fonts/big-shoulders-text-v1-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
  

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.5;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

p {
    margin-block-start: $spacing;
    margin-block-end: $spacing;
}

a {
    color: $primary;
    text-decoration: none;

    &:hover {
        color: $secondary;
    }

    &:focus {
        outline: 2px dotted $primary;
    }

    &:focus:hover {
        outline: 2px dotted $secondary;
    }
}

blockquote {
    background-color: $offWhite;
    padding: $spacing;
    font-size: .875em;

    @include desktop {
        margin: $spacing auto;
        width: 75%;
    }

    @include phone {
        margin: $spacing 0;
    }

    h4 {
        margin: 0;
        font-weight: normal;
        text-transform: uppercase;
        color: $neutral;
        letter-spacing: 2px;
    }

    h4, p {
        margin-block-start: $spacing/2;
        margin-block-end: $spacing/2;
    }

    h4:first-child, p:first-child {
        margin-block-start: 0;
    }

    h4:last-child, p:last-child {
        margin-block-end: 0;
    }

}