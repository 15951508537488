$white: white;
$primary: hsla(195%, 100%, 44%, 1);
$secondary: hsla(315%, 100%, 44%, 1);
$dark: hsla(254%, 15%, 17%, 1);
$black: hsla(210%, 11%, 4%, 1);

$offWhite: hsla(140%, 5%, 90%, 1);
$darkPrimary: hsla(196%, 100%, 40%, 1);
$darkSecondary: hsla(316%, 100%, 40%, 1);
$neutral: hsla(253%, 4%, 47%, 1);
$blueWhite: mix($primary, $white, 25%);

$spacing: 24px;
$border: 4px;

@mixin desktop {
    @media screen and (min-width: 1080px) {
        @content;
    }
}

@mixin tablet-or-larger {
    @media screen and (min-width: 640px) {
        @content;
    }
}

@mixin tablet {
    @media screen and (max-width: 1079px) and (min-width: 640px) {
        @content;
    }
}

@mixin tablet-or-smaller {
    @media screen and (max-width: 1079px) {
        @content;
    }
}

@mixin phone {
    @media screen and (max-width: 639px) {
        @content;
    }
}