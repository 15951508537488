@font-face {
  font-family: 'Big Shoulders Text';
  font-style: normal;
  font-weight: 700;
  src: local("Big Shoulders Text Bold"), local("BigShouldersText-Bold"), url("/fonts/big-shoulders-text-v1-latin-700.woff2") format("woff2"), url("/fonts/big-shoulders-text-v1-latin-700.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

p {
  -webkit-margin-before: 24px;
          margin-block-start: 24px;
  -webkit-margin-after: 24px;
          margin-block-end: 24px; }

a {
  color: #00a8e0;
  text-decoration: none; }
  a:hover {
    color: #e000a8; }
  a:focus {
    outline: 2px dotted #00a8e0; }
  a:focus:hover {
    outline: 2px dotted #e000a8; }

blockquote {
  background-color: #e4e7e5;
  padding: 24px;
  font-size: .875em; }
  @media screen and (min-width: 1080px) {
    blockquote {
      margin: 24px auto;
      width: 75%; } }
  @media screen and (max-width: 639px) {
    blockquote {
      margin: 24px 0; } }
  blockquote h4 {
    margin: 0;
    font-weight: normal;
    text-transform: uppercase;
    color: #75737d;
    letter-spacing: 2px; }
  blockquote h4, blockquote p {
    -webkit-margin-before: 12px;
            margin-block-start: 12px;
    -webkit-margin-after: 12px;
            margin-block-end: 12px; }
  blockquote h4:first-child, blockquote p:first-child {
    -webkit-margin-before: 0;
            margin-block-start: 0; }
  blockquote h4:last-child, blockquote p:last-child {
    -webkit-margin-after: 0;
            margin-block-end: 0; }

@charset "UTF-8";
.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column; }
  .App > .top {
    display: flex;
    flex-grow: 1;
    box-sizing: border-box;
    font-size: 16px;
    background-color: white; }
    @media screen and (max-width: 639px) {
      .App > .top {
        flex-direction: column; } }
    .App > .top > .column {
      display: flex;
      flex-direction: column;
      flex-grow: 1; }
      .App > .top > .column > .columnHeader {
        flex-shrink: 0;
        margin: 1em; }
    .App > .top > nav.column {
      text-align: right; }
      .App > .top > nav.column > .homeLink {
        padding: 24px 0;
        background-color: #282532;
        color: white;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        font-size: 2.5em;
        font-family: "Big Shoulders Text";
        font-weight: 700;
        margin: 48px 0; }
        @media screen and (max-width: 639px) {
          .App > .top > nav.column > .homeLink {
            margin: 0; } }
        .App > .top > nav.column > .homeLink > img {
          height: 1em;
          margin-right: .25em; }
        @media screen and (max-width: 1079px) and (min-width: 640px) {
          .App > .top > nav.column > .homeLink > img {
            height: 1.5em; }
          .App > .top > nav.column > .homeLink > span {
            display: none; } }
      @media screen and (min-width: 640px) {
        .App > .top > nav.column > ul > li {
          border-right: 4px solid #00a8e0; } }
      @media screen and (max-width: 639px) {
        .App > .top > nav.column > ul > li {
          height: 100%;
          box-sizing: border-box;
          vertical-align: bottom;
          border-bottom: 4px solid #00a8e0; } }
    .App > .top > aside.column {
      padding-top: 48px; }
      @media screen and (max-width: 1079px) {
        .App > .top > aside.column {
          display: none; } }
      .App > .top > aside.column > h4 {
        margin-left: 28px;
        color: #75737d;
        font-weight: normal;
        text-transform: uppercase;
        letter-spacing: 2px; }
      .App > .top > aside.column > ul > li {
        border-left: 4px solid #00a8e0; }
        .App > .top > aside.column > ul > li > a:focus {
          background-color: #bfe9f7; }
    .App > .top > nav.column, .App > .top > aside.column {
      flex-grow: 0;
      color: #282532; }
      @media screen and (min-width: 1080px) {
        .App > .top > nav.column, .App > .top > aside.column {
          min-width: 240px; } }
      @media screen and (min-width: 640px) {
        .App > .top > nav.column, .App > .top > aside.column {
          position: -webkit-sticky;
          position: sticky;
          top: 0;
          align-self: flex-start; } }
      .App > .top > nav.column > ul, .App > .top > aside.column > ul {
        display: flex;
        flex-direction: column;
        padding: 0;
        margin: 0;
        height: 100%;
        list-style-type: none; }
        @media screen and (max-width: 639px) {
          .App > .top > nav.column > ul, .App > .top > aside.column > ul {
            flex-direction: row;
            justify-content: center;
            box-shadow: 0 -4px 0 rgba(0, 0, 0, 0.1) inset; } }
        .App > .top > nav.column > ul > li, .App > .top > aside.column > ul > li {
          box-sizing: border-box; }
          .App > .top > nav.column > ul > li a, .App > .top > aside.column > ul > li a {
            display: block;
            padding: 12px 24px; }
            .App > .top > nav.column > ul > li a:focus, .App > .top > aside.column > ul > li a:focus {
              outline: none; }
            .App > .top > nav.column > ul > li a:focus-within, .App > .top > aside.column > ul > li a:focus-within {
              font-weight: bold; }
          .App > .top > nav.column > ul > li .current, .App > .top > aside.column > ul > li .current {
            background-color: #bfe9f7;
            font-weight: bold; }
      .App > .top > nav.column a, .App > .top > aside.column a {
        color: inherit;
        text-decoration: none; }
    .App > .top > nav + main {
      padding: 0 48px;
      box-sizing: border-box; }
      @media screen and (max-width: 1079px) {
        .App > .top > nav + main {
          padding: 0 24px; } }
    .App > .top > main {
      min-width: 40%;
      color: #090a0b;
      overflow-wrap: break-word;
      margin-bottom: 96px; }
      .App > .top > main .contentHeader {
        text-align: center;
        padding: 24px;
        margin-bottom: 24px; }
        @media screen and (max-width: 639px) {
          .App > .top > main .contentHeader {
            margin-left: -24px;
            margin-right: -24px; } }
        .App > .top > main .contentHeader h1 {
          margin: 24px;
          font-size: 4em;
          font-weight: normal;
          color: #090a0b; }
          @media screen and (max-width: 639px) {
            .App > .top > main .contentHeader h1 {
              font-size: 3em; } }
        .App > .top > main .contentHeader p {
          font-size: 1.25em;
          color: #282532; }
  .App > footer {
    background-color: #090a0b;
    color: #75737d;
    font-size: .75em; }
    .App > footer > .footerSections {
      display: flex;
      flex-wrap: wrap;
      list-style-type: none;
      margin: 0;
      padding: 0;
      line-height: 2; }
      @media screen and (min-width: 640px) {
        .App > footer > .footerSections {
          justify-content: center; } }
      .App > footer > .footerSections > li {
        min-width: 180px;
        flex-shrink: 1;
        padding: 2em;
        box-sizing: border-box; }
        @media screen and (min-width: 640px) {
          .App > footer > .footerSections > li {
            max-width: 25%; } }
        .App > footer > .footerSections > li > h3 {
          text-transform: uppercase;
          margin-top: 0;
          color: #e4e7e5; }
        .App > footer > .footerSections > li > p {
          display: inline-block;
          margin-top: 0; }
        .App > footer > .footerSections > li > .footerLinks {
          margin: 0;
          padding-left: 1em; }
          .App > footer > .footerSections > li > .footerLinks a {
            color: inherit; }

.contentSummaryList {
  list-style-type: none;
  margin: 0;
  padding: 0; }
  .contentSummaryList > .contentSummary:not(:last-child) {
    margin-bottom: 48px; }
  .contentSummaryList > .contentSummary > a {
    display: flex;
    position: relative;
    flex-direction: row;
    color: inherit; }
    .contentSummaryList > .contentSummary > a .contentSummaryThumbnail {
      flex: 0 0;
      height: 10em;
      margin-right: 24px; }
      @media screen and (max-width: 639px) {
        .contentSummaryList > .contentSummary > a .contentSummaryThumbnail {
          display: none; } }
    .contentSummaryList > .contentSummary > a .contentSummaryText {
      border-left: 4px solid #00a8e0;
      padding-left: 24px; }
      .contentSummaryList > .contentSummary > a .contentSummaryText > h3 {
        font-size: 2em; }
      .contentSummaryList > .contentSummary > a .contentSummaryText > *:first-child {
        margin-top: 0; }
      .contentSummaryList > .contentSummary > a .contentSummaryText > *:last-child {
        margin-bottom: 0; }

.coming-soon {
  color: #75737d !important; }
  .coming-soon::after {
    content: " 🔜︎"; }

.home {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: white; }
  .home > .homeHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 96px 48px;
    box-sizing: border-box;
    background-color: #090a0b;
    background-image: url("/images/Home - Header.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: white; }
    @media screen and (max-width: 1079px) {
      .home > .homeHeader {
        flex-direction: column; } }
    @supports (pointer-events: none) and (mix-blend-mode: difference) {
      .home > .homeHeader::before {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-image: url("/images/Home - Header (grain).png");
        content: " ";
        pointer-events: none;
        mix-blend-mode: difference; } }
    .home > .homeHeader > * {
      padding: 24px; }
    .home > .homeHeader > p {
      margin: 0;
      max-width: 640px;
      line-height: 2; }
      @media screen and (min-width: 640px) {
        .home > .homeHeader > p {
          font-size: 1.25em; } }
    .home > .homeHeader > .homeLogo {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center; }
      .home > .homeHeader > .homeLogo > img {
        height: 8em;
        margin-right: 2em; }
      .home > .homeHeader > .homeLogo > h1 {
        font-size: 8em;
        font-family: "Big Shoulders Text";
        font-weight: 700;
        margin: 0; }
      @media screen and (max-width: 1079px) {
        .home > .homeHeader > .homeLogo > img {
          height: 15vw;
          margin-right: 3vw; }
        .home > .homeHeader > .homeLogo > h1 {
          font-size: 15vw; } }
  .home > .homeSections {
    display: flex;
    margin: 0;
    padding: 0;
    list-style-type: none;
    background-color: white; }
    @media screen and (max-width: 1079px) {
      .home > .homeSections {
        flex-direction: column; } }
    .home > .homeSections > li {
      padding: 48px;
      flex: 1 1; }
      @media screen and (max-width: 1079px) {
        .home > .homeSections > li {
          padding: 24px; } }
      .home > .homeSections > li > h2 {
        font-size: 3em;
        color: #75737d;
        margin-top: 0; }
      .home > .homeSections > li > a {
        color: white; }
        .home > .homeSections > li > a > h3 {
          margin-top: 0;
          font-size: 2em;
          font-weight: normal; }
        .home > .homeSections > li > a > p {
          margin-bottom: 0; }

article > .contentHeader {
  background-color: #e4e7e5;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  text-align: left !important;
  padding-top: 48px !important;
  padding-bottom: 96px !important;
  margin-bottom: 48px !important; }
  @media screen and (min-width: 1080px) {
    article > .contentHeader {
      padding-left: 48px !important;
      padding-right: 48px !important; } }
  @media screen and (max-width: 639px) {
    article > .contentHeader {
      padding-left: 0 !important;
      padding-right: 0 !important; } }
  article > .contentHeader h1, article > .contentHeader p {
    display: table;
    margin: 0 !important;
    padding: 12px;
    background-color: rgba(40, 37, 50, 0.9);
    color: #e4e7e5 !important; }

article > .contents {
  color: #282532; }
  article > .contents .articleMetadata {
    margin-top: -12px;
    color: #75737d;
    font-size: .875em;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: 2px; }
  article > .contents h2 {
    font-size: 3em;
    font-weight: normal; }
  article > .contents h3 {
    font-size: 2em;
    font-weight: normal; }
  article > .contents ol, article > .contents ul {
    padding-left: 1em; }
  article > .contents li:not(:last-child) {
    margin-bottom: 12px; }
  article > .contents img {
    text-align: center;
    max-width: 100%; }

.Term > .termTitle h3 {
  margin-top: 48px;
  font-size: 1.5em;
  font-weight: bold;
  color: #090a0b; }

.Term > .termTitle .aka {
  font-size: .75em;
  font-style: italic;
  color: #75737d;
  margin-top: -12px; }

.Term .termDefinitionAndImage {
  display: flex;
  align-items: flex-start; }
  @media screen and (max-width: 1079px) {
    .Term .termDefinitionAndImage {
      flex-direction: column; } }
  .Term .termDefinitionAndImage > .termImage {
    text-align: right; }
    @media screen and (min-width: 1080px) {
      .Term .termDefinitionAndImage > .termImage {
        max-width: 33%;
        margin-left: 24px; } }
    @media screen and (max-width: 639px) {
      .Term .termDefinitionAndImage > .termImage {
        margin-left: -24px;
        margin-right: -24px; }
        .Term .termDefinitionAndImage > .termImage span {
          margin-left: 24px;
          margin-right: 24px; } }
    .Term .termDefinitionAndImage > .termImage img {
      display: block;
      max-width: 100%; }
    .Term .termDefinitionAndImage > .termImage span {
      color: #75737d;
      font-size: .75em;
      font-style: italic; }

.Term p, .Term ul {
  color: #282532; }

.termGroup {
  margin-top: 4em; }
  .termGroup h2 {
    display: flex;
    flex-direction: row;
    font-size: 2em;
    font-weight: normal;
    color: #75737d; }
    .termGroup h2:before, .termGroup h2:after {
      content: "";
      flex: 1 1;
      border-bottom: 2px solid #75737d;
      margin: auto; }
    .termGroup h2:before {
      margin-right: .5em; }
    .termGroup h2:after {
      margin-left: .5em; }

.Autosuggest_container__2WVkL {
  position: relative; }
  .Autosuggest_container__2WVkL input {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 0.5em;
    border: 4px solid #bfe9f7;
    font-size: inherit;
    color: #090a0b; }
    .Autosuggest_container__2WVkL input:focus {
      border-color: #00a8e0;
      outline: none; }
  .Autosuggest_container__2WVkL .Autosuggest_suggestionsList__bW1ja {
    list-style-type: none;
    margin: 0;
    padding: 0; }
    .Autosuggest_container__2WVkL .Autosuggest_suggestionsList__bW1ja .Autosuggest_suggestion__3CtP0 {
      width: 100%;
      padding: .5em;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: white;
      background-color: #00a8e0;
      border: 4px solid #00a8e0;
      cursor: pointer; }
    .Autosuggest_container__2WVkL .Autosuggest_suggestionsList__bW1ja .Autosuggest_suggestionHighlighted__1UWFi {
      background-color: #0096cc;
      border-color: #0096cc; }

.sortSelect {
  padding: .5em;
  box-sizing: border-box;
  font-size: inherit;
  color: #282532;
  border: 4px solid #bfe9f7;
  outline: none; }
  .sortSelect:focus {
    border-color: #00a8e0; }
  .sortSelect:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000; }

.glossary > .sortAndFilter {
  display: flex;
  align-items: center; }
  @media screen and (max-width: 639px) {
    .glossary > .sortAndFilter {
      flex-direction: column; } }
  .glossary > .sortAndFilter h2 {
    font-weight: normal;
    margin: 0;
    margin-right: 12px; }
  .glossary > .sortAndFilter > div {
    display: inline-block;
    width: 180px;
    flex-shrink: 1;
    margin: 12px; }
    .glossary > .sortAndFilter > div > * {
      width: 100%;
      height: calc(2em + 4px*2 + 4px); }

.contributorSections {
  display: table;
  padding: 0;
  color: #282532; }
  .contributorSections > .contributorSection {
    display: table-row; }
    .contributorSections > .contributorSection > * {
      display: table-cell;
      padding: 48px 0;
      border-bottom: 1px solid #e4e7e5; }
    .contributorSections > .contributorSection:first-child > * {
      padding-top: 0; }
    .contributorSections > .contributorSection > .contributorSectionHeader {
      position: relative;
      text-align: right;
      padding-right: 24px; }
      .contributorSections > .contributorSection > .contributorSectionHeader > h2 {
        font-size: 2em;
        font-weight: normal;
        margin-top: 24px;
        color: #090a0b; }
        @media screen and (max-width: 639px) {
          .contributorSections > .contributorSection > .contributorSectionHeader > h2 {
            font-size: 1.5em; } }
      .contributorSections > .contributorSection > .contributorSectionHeader > p {
        font-style: italic; }
        @media screen and (max-width: 639px) {
          .contributorSections > .contributorSection > .contributorSectionHeader > p {
            font-size: .75em; } }
      .contributorSections > .contributorSection > .contributorSectionHeader > img {
        width: 100px; }
    .contributorSections > .contributorSection > .contributorSectionContent {
      padding-left: 24px; }
      @media screen and (min-width: 640px) {
        .contributorSections > .contributorSection > .contributorSectionContent {
          font-size: 1.25em; } }

